import React, { useState, useEffect } from "react";
import { Box, Skeleton, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NftCollection = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [imageKey, setImageKey] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [imageError, setImageError] = useState(false);


  // const imageUrl =
  //   "https://dreamgirls-strapi.s3.us-west-2.amazonaws.com/public/preview/56-0.png";
  const imageUrl = `${process.env.REACT_APP_META_URL}/public/preview/${data?.girlId?.id}-${data?.collectionid}.png`; 


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setOverlayVisible(true);
  //     setTimeout(() => {
  //       setImageKey((prevKey) => prevKey + 1);
  //     }, 300);
  //   }, 6000); 

  //   return () => clearInterval(interval);
  // }, []);


  const handleImageLoad = () => {
    setLoading(false);
    setOverlayVisible(false);
    setImageError(false); 
  };


  const handleImageError = () => {
    setLoading(false);
    setImageError(true); 
  };

  const navigate = useNavigate();
  const handleNFT = (id, collectionid) => {
    navigate(`/collection/${id}/${collectionid}`);
  };

  return (
    <div>
      <Box
        sx={{
          border: "1px solid #cecfd033",
          borderRadius: "12px",
          color: "#fff",
          background: "#1d1e1f",
          position: "relative", 
          overflow: "hidden",
          transition: "all 0.15s ease-in-out 0s",
          "&:hover": {
            transform: "translateY(-3px)",
          },
        }}
      >
        {/* Main Image */}
        <Box
          key={imageKey} 
          sx={{
            position: "relative",
            width: "100%",
            height: "380px",
            backgroundImage: `url(${imageUrl}?${imageKey})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            // opacity: loading ? 0 : 1,
            // transform: loading ? "scale(1.05)" : "scale(1)",
            // transition:loading?"": "opacity 0.6s ease-in-out, transform 0.5s ease-in-out", 
            zIndex: 1,
          }}
        >
{/*   
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Semi-transparent overlay
              zIndex: 2, // Ensure overlay is above the image
              opacity: overlayVisible ? 1 : 0, // Fade in/out overlay
              transform: overlayVisible ? "translateY(0)" : "translateY(-100%)", // Slide-in effect from top
              transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out", // Smooth fade and slide 
            }}
          /> */}

       
          {loading || imageError ? (
            <Box
              sx={{
               
                p:"1rem",
                  height: "100%",
                  "& .MuiSkeleton-root": {
                    transformOrigin: "0 0%",
                    transform: "scale(1,1)",
                  },
              }}
            >
              <Skeleton
                component="p"
                sx={{ background: "#ffffffb5", height: "100%" }}
              />
            </Box>
          ) : null}

       
          <img
            src={`${imageUrl}?${imageKey}`}
            alt="NFT"
            style={{ display: "none" }}
            onLoad={handleImageLoad}
            onError={handleImageError} 
          />
        </Box>

        <Box p={"0.5rem"}>
          <Button
            onClick={() => handleNFT(data?.girlId?.id, data?.collectionid)}
            disableRipple
            sx={{
              width: "100%",
              background:
                "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
              borderRadius: "8px",
              border: "2px solid #c79d54",
              color: "#000",
              px:"15px",
              textTransform:"capitalize",
              justifyContent:"space-between",
              textAlign:"start"
            }}
          >
       
        <Box>
          <Typography sx={{
            opacity:"0.6"
          }}>Name</Typography>
          <Typography fontWeight={"600"} fontSize={"18px"}>{data?.girlId?.username}</Typography>
         </Box>
         <Box>
          <Typography sx={{
            opacity:"0.6"
          }}>Starts from </Typography>
          <Typography textAlign={"end"} fontWeight={"600"} fontSize={"18px"}>$20</Typography>
         </Box>
       
            
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default NftCollection;
